import React, { FunctionComponent } from 'react';
import Section from "../../components/Section";
import Hero from "../../components/Hero";
import FeaturedPostImage from "../../assets/images/FeaturedPostImage.jpg";
import Heading from "../../components/Heading";
import { Link, graphql } from "gatsby";
import LocalizedLink from '../../components/LocalizedLink';
import Button from "../../components/Button/Button";
import useTranslations from '../../components/useTranslations';


const getCategoryCountByName = (categories: any[]) => {
    return categories.reduce(
        (prev, { node: { name } }) => {
            prev[name] = (prev[name] || 0) + 1
            return prev
        },
        {}
    )

}

interface BlogProps {
    data?: any
}

const Blog: FunctionComponent<BlogProps> = (props) => {
    const { pages: { blog } } = useTranslations()

    const featuredPosts = props.data?.featuredPosts.edges || [];
    const allCategories = props.data?.allBlogCategoryNode.edges || [];
    const allCategoryCounts = getCategoryCountByName(props.data?.allBlogCategoryCountNode.edges || []);

    let firstPost = null;

    if (featuredPosts.length > 0) {
        const { node: post } = featuredPosts[0]
        firstPost = post;
    }

    return (
        <>
            {firstPost && <Section className={'py-12'}>
                {/* Featured post */}
                <div className={'lg:p-12 bg-brand-5 grid lg:grid-cols-2 lg:gap-16'}>
                    <div className={''}>
                        <Link
                            to={`/${firstPost.frontmatter.locale}/blogs/${firstPost.frontmatter.slug}`}>
                            <img src={firstPost.frontmatter.featuredimage} alt="" className={'w-full'} />
                        </Link>
                    </div>
                    <div className={'p-4'}>
                        <p className={'text-sm text-ink-60 opacity-50'}>
                            {firstPost.frontmatter.date}
                        </p>
                        <Link
                            to={`/${firstPost.frontmatter.locale}/blogs/${firstPost.frontmatter.slug}`}>
                            <Heading type='h4' className={'mt-4 font-semibold lg:text-h3'}>{firstPost.frontmatter.title}
                            </Heading>
                        </Link>
                        <p className={'mt-4'}>
                            {firstPost.frontmatter.description}
                        </p>
                        <p className={'mt-4'}>
                            <Link
                                to={`/${firstPost.frontmatter.locale}/blogs/${firstPost.frontmatter.slug}`}>
                                <Button defaultStyle={'font-serif inline-flex items-center rounded-full text-blue-100'}
                                    variant={'transparent'} chevronVariant={'blue'}>
                                    {blog.actions.continueReading}
                                </Button>
                            </Link>
                        </p>
                    </div>
                </div>
            </Section>}
            <Section>
                <div className={'grid lg:grid-cols-3 lg:gap-12'}>
                    <div className={'col-span-2 space-y-16'}>
                        {featuredPosts.map(({ node: post }, index) => (
                            <div className={'grid lg:grid-cols-3 gap-x-2'}>
                                <div className={'lg:col-span-2'}>
                                    <p className={'text-sm text-ink-60 opacity-50'}>
                                        {post.frontmatter.date}
                                    </p>
                                    <Heading type="h5"
                                             className={'mt-2 font-semibold'}>{post.frontmatter.title}</Heading>
                                    <p className={'mt-2'}>
                                        {post.frontmatter.description}
                                    </p>
                                    <p className={'mt-2'}>
                                        <Link
                                            to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}>
                                            <Button variant={'transparent'}
                                                    defaultStyle={'font-serif inline-flex items-center font-medium rounded-full'}>
                                                {blog.actions.continueReading}
                                            </Button>
                                        </Link>
                                    </p>
                                </div>
                                <div className={'lg:max-w-xs lg:justify-self-end grid items-center'}>
                                    <Link
                                        to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}>
                                        <img src={post.frontmatter.featuredimage} alt="" className={'w-full'} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {/*<div className={''}>*/}
                        {/*    <LocalizedLink to={'/blogs'}>*/}
                        {/*        <Button className={'font-semibold'} defaultStyle={'font-serif inline-flex items-center rounded-full'} variant={'border'}>*/}
                        {/*            {blog.actions.loadMore}*/}
                        {/*        </Button>*/}
                        {/*    </LocalizedLink>*/}
                        {/*</div>*/}
                    </div>
                    <div className={''}>
                        {/*<div className={''}>{blog.search}</div>*/}
                        <div className={'mt-6'}>
                            <Heading type='h5' className={'mb-4 font-semibold'}>{blog.featuredPosts}</Heading>
                            <div className={'space-y-6'}>
                                {featuredPosts.map(({ node: post }, index) => (
                                    <div>
                                        <div>
                                            <p className={'text-sm text-ink-60 opacity-50 mb-1'}>
                                                {post.frontmatter.date}
                                            </p>
                                            <Link to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}
                                                className={'text-ink-60 font-semibold hover:underline'}>{post.frontmatter.title}</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'mt-16'}>
                            <Heading type='h5' className={'mb-5 font-semibold'}>{blog.categories}</Heading>
                            <div className={'space-y-6'}>
                                {allCategories.map(({ node: category }, index) => (
                                    <div className={'flex justify-between items-center'}>
                                        <LocalizedLink to={`/blogs/${category.slug}`} className={'text-ink-60 font-semibold hover:underline'}>
                                            {category.name}
                                        </LocalizedLink>
                                        <p className={'text-sm text-ink-60 opacity-50 mb-1'}>({allCategoryCounts[category.name]})</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export const query = graphql`
  query Posts($locale: String!) {
    featuredPosts: allMdx(filter: {frontmatter: {locale: {eq: $locale}}}, sort: {order: ASC, fields: frontmatter___date}) {
    edges {
      node {
        id
        frontmatter {
            locale
            title
            date(formatString: "DD.MM.YYYY")
            description
            slug
            featuredimage
        }
      }
    }
  },
    allBlogCategoryNode(filter: {locale: {eq: $locale}}) {
    edges {
      node {
        id
        name
        slug
        locale
            }
        }
    }

    allBlogCategoryCountNode{
        edges {
          node {
            locale
            name
            count
          }
        }
      }
  }
`;

export default Blog;
